import $ from 'jquery';
import Component from 'Component';
import Macy from 'macy';

class SocialMedia extends Component {
  $socialMedia;
  macyConfig;
  macyInstance;

  constructor(selector) {
    super(selector);

    this.macyConfig = {
      container: '.social-media__grid',
      columns: 2,
      margin: {
        x: 18,
        y: 18,
      },
      mobileFirst: true,
      trueOrder: true,
      breakAt: {
        769: {
          columns: 3,
        },
        1024: {
          columns: 4,
        },
      },
    };

    this.init(() => {
      const { $element } = this;

      this.$socialMedia = $element;
      const $socialMediaChildren = $('.social-media__grid-item');
      const $socialMediaLoadMore = $('.js-sm-load-more');
      const $socialMediaModal = $('.js-sm-modal');
      const $socialMediaModalClose = $('.js-sm-modal-close');
      let postsLoaded = $socialMediaChildren.length;

      if (this.$socialMedia && this.$socialMedia.length) {
        $(() => {
          // Initialize Macy
          this.initializeMacy();

          // Reinitialize Macy on window resize
          $(window).on('resize', () => {
            this.macyInstance.recalculate(true);
            updateIframeSize();
          });

          function createIframe(url, width, height, options = {}) {
            const iframe = document.createElement('iframe');
            iframe.setAttribute('src', url);
            iframe.style.width = width;
            iframe.style.height = height;
            if (options.backgroundColor) {
              iframe.style.backgroundColor = options.backgroundColor;
            }
            if (options.allow) {
              iframe.setAttribute('allow', options.allow);
            }
            if (options.allowFullscreen) {
              iframe.setAttribute('allowfullscreen', 'true');
            }
            return iframe;
          }

          function createFacebookPostIframe(url) {
            const iframe = createIframe(url, '552px', '613px', { backgroundColor: 'white' });
            $socialMediaModal.find('.social-media__modal-inner').append(iframe);
            $socialMediaModal.addClass('modal-type--facebook');
          }

          function createFacebookVideoIframe(url, item) {
            const height = item.data('layout') === 'portrait' ? '534px' : '387px';
            const width = item.data('layout') === 'portrait' ? '300px' : '688px';
            const iframe = createIframe(url, width, height);
            $socialMediaModal.find('.social-media__modal-inner').append(iframe);
            $socialMediaModal.addClass('modal-type--facebook-video');
          }

          function createInstagramEmbed(item) {
            $(`.instagram-embed[data-instagram-embed-id="${item.data('instagram-embed')}"]`).appendTo($socialMediaModal.find('.social-media__modal-inner'));
            $socialMediaModal.addClass('modal-type--instagram');
          }

          function convertYouTubeUrlToEmbed(url) {
            const regex = /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)/;
            const match = url.match(regex);
            if (match && match[1]) {
              return `https://www.youtube.com/embed/${match[1]}`;
            }
            return url;
          }
          
          function createYouTubeIframe(url) {
            const embedUrl = convertYouTubeUrlToEmbed(url);
            const iframe = createIframe(embedUrl, '900px', '700px', {
              allow: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
              allowFullscreen: true
            });
            
            // Set width and height based on screen size
            setIframeSize(iframe);
            
            $socialMediaModal.find('.social-media__modal-inner').append(iframe);
            $socialMediaModal.addClass('modal-type--youtube');
          }

          function createMediaImage(url) {
            const img = document.createElement('img');
            img.setAttribute('src', url);
            img.style.width = '100%';
            img.style.height = 'auto';
            img.style.minWidth = '300px';
            $socialMediaModal.find('.social-media__modal-inner').append(img);
            $socialMediaModal.addClass('modal-type--media');
          }

          function createModalIframe(url, item) {
            // Remove any existing content before adding new content
            const $modalInner = $socialMediaModal.find('.social-media__modal-inner');
            if ($modalInner.children().length > 0) {
              $modalInner.empty();
            }

            if (url.indexOf('facebook.com/plugins/post') >= 0) {
              createFacebookPostIframe(url);
            } else if (url.indexOf('facebook.com/plugins/video') >= 0) {
              createFacebookVideoIframe(url, item);
            } else if (url.indexOf('instagram') >= 0) {
              createInstagramEmbed(item);
            } else if (url.indexOf('youtube.com') >= 0) {
              createYouTubeIframe(url);
            } else if (item.hasClass('social-media__grid-item--media')) {
              createMediaImage(url);
            }
          }

          function setIframeSize(iframe) {
            if (window.innerWidth < 400) {
              iframe.style.width = '300px';
              iframe.style.height = '220px';
            } else if (window.innerWidth < 769) {
              iframe.style.width = '320px';
              iframe.style.height = '250px';
            } else if (window.innerWidth < 1024) {
              iframe.style.width = '500px';
              iframe.style.height = '400px';
            } else {
              iframe.style.width = '900px';
              iframe.style.height = '700px';
            }
          }

          function updateIframeSize() {
            const iframe = $socialMediaModal.find('iframe');
            if (iframe.length > 0) {
              setIframeSize(iframe[0]);
            }
          }

          function destroyModalIframe() {
            if (
              $socialMediaModal.is(
                '.modal-type--facebook, .modal-type--facebook-video, .modal-type--youtube'
              )
            ) {
              $socialMediaModal.find('iframe').remove();
            } else if ($socialMediaModal.hasClass('modal-type--instagram')) {
              $socialMediaModal.find('.instagram-embed').appendTo('.instagram-embeds');
            } else if ($socialMediaModal.hasClass('modal-type--media')) {
              $socialMediaModal.find('img').remove();
            }
            $socialMediaModal.removeClass(
              'modal-type--facebook modal-type--facebook-video modal-type--instagram modal-type--youtube modal-type--media'
            );
          }

          // Click events for each link using event delegation
          $('.social-media__grid').on('click', '.social-media__grid-item', function (e) {
            const isMedia = $(this).hasClass('social-media__grid-item--media');

            // Allow modal for media type on all screen sizes, but restrict others to larger screens
            if (!isMedia && ($(this).data('modal') !== true || window.innerWidth < 769)) return;

            let embedUrl = $(this).data('embed');
            if (!embedUrl) return;

            e.preventDefault();
            $socialMediaModal.addClass('open');
            setTimeout(() => {
              $socialMediaModal.css('opacity', 1);
            }, 0);
            createModalIframe(embedUrl, $(this));
          });

          // Modal open/close with transition
          $socialMediaModal.css({
            transition: 'opacity 0.5s ease',
            opacity: 0,
          });

          // Modal open/close
          $socialMediaModalClose.each(function () {
            $(this).on('click', function (e) {
              e.preventDefault();
              $socialMediaModal.css('opacity', 0);
              setTimeout(() => {
                $socialMediaModal.removeClass('open');
                destroyModalIframe();
              }, 500);
            });
          });

          // Load more button functionality
          let postsPerLoad = 4;
          let postsCurrentlyDisplayed = postsPerLoad;

          if (postsLoaded > postsPerLoad) {
            $socialMediaLoadMore.on('click', (e) => {
              e.preventDefault();

              // Load next set of posts
              let $nextPosts = $socialMediaChildren.slice(
                postsCurrentlyDisplayed,
                postsCurrentlyDisplayed + postsPerLoad
              );
              $nextPosts.show();
              postsCurrentlyDisplayed += postsPerLoad;

              // Recalculate the layout
              setTimeout(() => {
                this.macyInstance.recalculate();
              }, 150);

              $socialMediaLoadMore.hide();
            });
          } else {
            $socialMediaLoadMore.remove();
          }
        });
      }
    });
  }

  initializeMacy() {
    this.macyInstance = Macy(this.macyConfig);
  }
}

export default SocialMedia;
